import React from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'

export const Footer = () => (
  <FooterContainer>
    <div className="main-footer">
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4 style={{fontSize: '1.8rem'}}>Kontakt</h4>
              <p>OCEAN DENT s.r.o.</p>
              <p>Rastislavova 786/45</p>
              <p>040 01 Košice</p>
              <p>oceandentkosice@gmail.com</p>
              <p>+421 910 399 793</p>
            </div>
            <div className="col-md-6">
              <h4 style={{fontSize: '1.8rem'}}>Ordinačné hodiny</h4>
              <Table responsive borderless>
                <tbody style={{color: 'white'}}>
                  <tr>
                    <td>Pondelok:</td>
                    <td>08:30 - 15:00</td>
                  </tr>
                  <tr>
                    <td>Utorok:</td>
                    <td>08:30 - 15:00</td>
                  </tr>
                  <tr>
                    <td>Streda:</td>
                    <td>08:30 - 15:00</td>
                  </tr>
                  <tr>
                    <td>Štvrtok:</td>
                    <td>08:30 - 14:30</td>
                  </tr>
                  <tr>
                    <td>Piatok:</td>
                    <td>08:30 - 14:00</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="footer-bottom">
            <p className="text-xs-center">
                            &copy;{new Date().getFullYear()} Ocean Dent s.r.o.  - All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </FooterContainer>
)

const FooterContainer = styled.footer`
    .main-footer {
        height: 400px;
    }
    .footer-middle {
        background: #5dc2de;
        padding-top: 3rem;
        color: white;
    }
    .footer-bottom {
        padding-top: 3rem;
        padding-bottom: 2rem;
    }
    td {
        padding: 5px !important;
    }
    h4 {
        margin-bottom: 2%;
    }
    p {
        margin-bottom: 2%;
      }
`
