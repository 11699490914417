import React, { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'gatsby'
import { FaEnvelope, FaPhone, FaMapMarker } from 'react-icons/fa'


function setOverflow({ menuOpen}) {
  if (typeof window === 'undefined') return
  else if (menuOpen){
    document.querySelector('body').style.overflow = 'hidden'
  } else {
    document.querySelector('body').style.overflow = 'visible'
  }
}

export default function NavigationBar() {

  const [menuOpen, setMenuOpen] = useState(false)

  const activeStyle = {
    color: '#4b8ebd',
  }

  useEffect(() => {
    setOverflow({ menuOpen })
  },[menuOpen])

  return (
    <Navbar expand="lg" className="fixed-top d-md-block d-sm-flex align-items-center">
      <div className="navbar-info w-100 d-none d-md-flex align-items-center px-lg-5 pl-md-4">
        <span className="mr-4"><FaPhone/> +421 910 399 793</span>
        <span className="mx-4"><FaMapMarker/> Rastislavova 786/45, 040 01 Košice</span>
        <span className="mx-4" ><FaEnvelope/> oceandentkosice@gmail.com</span>
      </div>
      <div className="navbar-main w-100 d-flex px-5 justify-content-between">
        <Navbar.Brand href="/"><img src={'/logo.png'} alt="logo" style={{width:'225px'}}></img></Navbar.Brand>
        <Navbar.Toggle className="mr-3" aria-controls="responsive-navbar-nav" onClick={() => setMenuOpen(!menuOpen)}/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link onClick={() => setMenuOpen(false)} activeStyle={activeStyle} to="/">Domov</Link>
            <Link onClick={() => setMenuOpen(false)} activeStyle={activeStyle} to="/onas" >O nás</Link>
            <Link onClick={() => setMenuOpen(false)} activeStyle={activeStyle} to="/sluzby" >Služby</Link>
            <Link onClick={() => setMenuOpen(false)} activeStyle={activeStyle} to="/cennik" >Cenník</Link>
            <Link onClick={() => setMenuOpen(false)} activeStyle={activeStyle} to="/kontakt" >Kontakt</Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}