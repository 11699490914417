import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";

export default class CookieBar extends Component {

    componentDidMount() {
        if(getCookie('cookiesAccepted')){
            return null;
        }
    }
    render(){
        return(
                <CookieConsent
                    location="bottom"
                    buttonText="Súhlasím"
                    cookieName="cookiesAccepted"
                    style={{ background: "white", borderTop: "1px solid #5dc2de", color: 'dimgrey' }}
                    buttonStyle={{ background: "#5dc2de", color: "white", fontSize: "15px", borderRadius: "15px", padding: "8px 20px 8px 20px" }}
                    expires={150}
                >
                    Táto webová stránka používa súbory cookies. Jej prezeraním súhlasíte s ich používaním a ukladaním do Vášho prehliadača.{" "}
                </CookieConsent>
        )
    }
}
function escape(string) {
    return string.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1')
}
function getCookie(name) {
    const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`))
    return match ? match[1] : null
}